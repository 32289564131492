@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Bold.eot');
    src: url('/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-Bold.woff2') format('woff2'),
        url('/fonts/Poppins-Bold.woff') format('woff'),
        url('/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Medium.eot');
    src: url('/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-Medium.woff2') format('woff2'),
        url('/fonts/Poppins-Medium.woff') format('woff'),
        url('/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Regular.eot');
    src: url('/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-Regular.woff2') format('woff2'),
        url('/fonts/Poppins-Regular.woff') format('woff'),
        url('/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
