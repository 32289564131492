@tailwind base;
@tailwind components;
html {
    @apply min-w-full overflow-x-hidden !important;
}
body {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-common-white font-theme text-lg font-normal leading-normal text-interface-900 lg:overflow-x-hidden ipad:text-base;
}
.container {
    @apply mx-auto max-w-[1110px] overflow-hidden lg:max-w-[90%];
}
#shadow-host-companion {
    @apply hidden;
}
section {
    @apply relative py-[100px] ipad:py-16 mobile:py-10;
}
section.secondary-section {
    @apply bg-gradient-to-b from-interface-200 via-interface-200 to-secondary-100;
}
h1 {
    @apply text-[70px] font-bold leading-[82px] ipad:text-[55px] mobile:text-3xl mobile:leading-10;
}
h1 br {
    @apply mobile:hidden;
}
h2 {
    @apply mb-6 text-5xl font-bold ipad:mb-3 ipad:text-4xl;
}
h3 {
    @apply mb-4 text-[32px] font-medium leading-8 ipad:text-2xl ipad:leading-7;
}
a {
    @apply text-primary-500;
}
/* Header */
header {
    @apply absolute top-0 z-20 w-full transition-all duration-100;
}
header.sticky-header {
    @apply fixed bg-common-white shadow;
}
header .container {
    @apply max-w-[1160px] lg:max-w-[90%];
}
.reverse-logo {
    @apply hidden;
}
header.sticky-header .primary-logo {
    @apply hidden;
}
header.sticky-header .reverse-logo {
    @apply block;
}
.inner {
    @apply flex items-center py-4 mobile:mt-3 mobile:flex-col;
}
/* Navigation */
.navigation {
    @apply flex items-center justify-center text-common-white;
}
.navigation li a {
    @apply mx-1 cursor-pointer rounded-md px-4 py-2 text-base text-common-white hover:text-primary-500 mobile:mx-1 mobile:px-1 mobile:text-sm;
}

.navigation li a.active {
    @apply text-primary-500 hover:text-primary-500;
}
/*
    Fixed header
*/
.header.fixed-header .navigation li a {
    @apply text-interface-950 hover:text-primary-500;
}
.header.fixed-header .navigation li a.active {
    @apply text-primary-500;
}
.header.fixed-header {
    @apply top-[-100px] opacity-0  shadow transition-all duration-100;
}
.header.fixed-header.sticky-header {
    @apply fixed top-0 bg-common-white opacity-100;
}
.header.fixed-header.sticky-header .inner {
    @apply mobile:mt-0 mobile:pt-1;
}
.btn {
    @apply flex h-[42px] w-full items-center justify-center rounded-full bg-primary-700 px-6 pt-0.5 text-base text-common-white transition-all duration-500 hover:bg-primary-800;
}
/* Banner */
.hero-section {
    @apply h-screen bg-[#000553] bg-cover bg-no-repeat py-0 text-common-white ipad:max-h-[650px] mobile:max-h-[450px];
}
.hero-section .lines {
    background-size: auto 100%;
    @apply absolute right-0 top-0 h-full w-full bg-right bg-no-repeat opacity-70 ipad:opacity-40;
}
.hero-section .content {
    @apply absolute left-0 right-0 top-0 flex h-full w-full items-center justify-center text-center;
}
.hero-section .content h1 {
    @apply mb-7 text-common-white ipad:mb-3;
}
.hero-section .content p {
    @apply mx-auto max-w-[820px] text-2xl leading-9 ipad:text-xl mobile:text-base;
}
.industries-list {
    @apply relative -mb-11;
}
.industries-list .item {
    @apply mb-12 flex gap-16 text-interface-700 even:flex-row-reverse last-of-type:mb-0 ipad:gap-6 mobile:flex-col-reverse mobile:even:flex-col-reverse;
}
.industries-list .item .content {
    @apply flex items-center;
}
.industries-list .item .image {
    @apply w-full max-w-[523px] flex-shrink-0 overflow-hidden rounded-br-[32px] rounded-tl-[32px] mobile:w-full mobile:max-w-full;
}
.industries-list .item .image img {
    @apply mobile:max-w-full;
}
.features li {
    @apply relative mb-1 ml-3 pl-6 text-interface-700 ipad:ml-0 mobile:pl-4;
}
.features li:before {
    content: '';
    @apply absolute left-0 top-[10px] h-1 w-1 rounded-full bg-interface-700 mobile:top-2;
}
.form-group {
    @apply mb-6;
}
.form-group label {
    @apply mb-1.5 block text-sm font-medium;
}
.form-control {
    border: 1px solid rgba(255, 255, 255, 0.1);
    @apply h-11 w-full rounded-md bg-common-black px-3 pt-0.5 text-sm text-interface-300 shadow outline-none focus:border-primary-500;
}
.form-control.error,
.form-control.invalid {
    @apply border-[#dc2626] focus:border-[#dc2626];
}
textarea.form-control {
    @apply h-[112px] pt-3;
}
/* ============
    Animate css
============= */
:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}
.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.animate__animated.animate__repeat-1 {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: var(--animate-repeat);
    animation-iteration-count: var(--animate-repeat);
}
.animate__animated.animate__repeat-2 {
    -webkit-animation-iteration-count: calc(1 * 2);
    animation-iteration-count: calc(1 * 2);
    -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
    animation-iteration-count: calc(var(--animate-repeat) * 2);
}
.animate__animated.animate__repeat-3 {
    -webkit-animation-iteration-count: calc(1 * 3);
    animation-iteration-count: calc(1 * 3);
    -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
    animation-iteration-count: calc(var(--animate-repeat) * 3);
}
.animate__animated.animate__delay-1s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}
.animate__animated.animate__delay-2s {
    -webkit-animation-delay: calc(1s * 1);
    animation-delay: calc(1s * 1);
    -webkit-animation-delay: calc(var(--animate-delay) * 1);
    animation-delay: calc(var(--animate-delay) * 1);
}
.animate__animated.animate__delay-3s {
    -webkit-animation-delay: calc(1s * 1.5);
    animation-delay: calc(1s * 1.5);
    -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
    animation-delay: calc(var(--animate-delay) * 1.5);
}
.animate__animated.animate__delay-4s {
    -webkit-animation-delay: calc(1s * 2);
    animation-delay: calc(1s * 2);
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}
.animate__animated.animate__delay-5s {
    -webkit-animation-delay: calc(1s * 2.5);
    animation-delay: calc(1s * 2.5);
    -webkit-animation-delay: calc(var(--animate-delay) * 2.5);
    animation-delay: calc(var(--animate-delay) * 2.5);
}
@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@media only screen and (min-width: 900px) {
    .section.active .animate__slideInLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
        @apply opacity-100;
    }
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.section.active .animate__slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    @apply opacity-100;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.section.active .animate__slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    @apply opacity-100;
}

/* Sliding entrances */
@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.section.active .animate__slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    @apply opacity-100;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.section.active .animate__fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    @apply opacity-100;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.section.active .animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 0px auto;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@tailwind utilities;
